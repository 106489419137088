
input {
	font-size: 16px;
    padding: 6px 10px;
	border-radius: 6px;
	border: 1px solid #ccc;
}

input[type=submit] {
	font-size: 16px;
    padding: 10px 10px;
	font-weight: 600;
	border: none;
	color: #fff;
	background: #110f2e;
	border-radius: 6px;
	min-width: 100px;
}

button {
	font-size: 16px;
    padding: 10px 14px;
	font-weight: 600;
	border: none;
	color: #fff;
	background: #110f2e;
	border-radius: 6px;
	cursor: pointer;
	min-width: 100px;
}


button:hover, input[type=submit]:hover {

	background: #110f2ec7;

}


.App {
	min-height: 100vh;
	background-color: #F8F8FB;
	
}

.top-bar {
	height: 60px;
	width: 100vw;
	overflow: hidden;
	background-color: rgb(65, 65, 65);
	color: white;
	position: fixed;
	left: 0;
	top: 0;

	/* box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.2); */
	z-index: 600;
}

.content-wrapper {
	padding-left: 0px; 
	padding-top: 0px;
	overflow: hidden;
	display: flex;
}

.top-bar h1 {
	margin: 0px;
	padding: 10px;
}

.top-bar .title-main {
	margin: 0; 
	padding: 0; 
	padding-left: 5px; 
	font-weight: normal; 
	font-family: Murecho;
}

.top-bar .title-sub {
	margin: 0; 
	padding: 0; 
	padding-left: 5px;
}

.top-bar .title-main {
	font-size: 1.5em;
}

.top-bar .title-sub {
	font-size: 0.9em;
}

.profile-menu-button {
	padding: 15px 30px 15px 30px;
}

.profile-menu-button:hover {
	cursor: pointer;
	background-color: #b8b8b8;
}

.side-bar {
	height: 100vh;
	width: 250px;
	flex-shrink: 0;
	overflow: hidden;
	background-color: #110f2e;
	color: rgb(0, 0, 0);
	/* position: fixed; */
	left: 0;
	top: 0;
	z-index: 501;
	color: #fff !important;
	/* box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1); */
	padding: 15px;
	box-sizing: border-box;
}

.side-bar-content {
	margin-top: 0;
	padding: 0px;
	box-sizing: border-box;
}

.sidebar-button {
	background-color: inherit;
	color: #fff !important;
	text-decoration: none;
	padding: 12px 18px;
	font-size: 16px;
	font-weight: 500;
	display: flex;
    justify-content: center;
    align-items: center;
	border-radius: 6px;
}


.sidebar-button .icon {
	height: 24px;
	/* opacity: 0.7; */
	vertical-align: middle;
}

.sidebar-button .icon-label {
	margin: auto;
	vertical-align: middle;
	margin-left: 12px;
	font-size: 1em;
}

.sidebar-button:hover {
	background-color: #6257ff;
	
}

.sidebar-button.active {
	background-color: #6257ff;
}


.page-title {
	padding-bottom: 20px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	margin-right: 20px;
}

.copyright {
	vertical-align: bottom;
	height: 5vh;
	font-size: 0.6em;
	display: none;
}

.device-container {
    display: flex;
    flex-wrap: wrap;
	box-sizing: border-box;
}

.device-box {
	
    /*height: 20vw;*/
    margin: 1vw;
    box-sizing: border-box;
	/* box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1); */
}

.device-box .data-table {
	width: 100%;
	table-layout: fixed;
	padding: 5px;
	padding-top: 5px;
	border-spacing: 0;
}

.device-box .data-table th {
	text-align: start;
	background-color: #110f2e79;
	color: #fff;
}

.device-box-active {
    background-color: #f5f5f5;
    color: #313131;
}

.device-box-active:hover {
    background-color: #fff;
}

.device-box-uvc {
	background-color: rgb(217, 230, 255);
}

.uvc-on {
	background-color: rgb(217, 230, 255);
}

.device-inactive {
	background-color: rgb(236, 236, 236);
	color: rgb(124, 124, 124);
}

.report-device {
	background-color: #fff;
	color: rgb(124, 124, 124);
}

.device-error {
	background-color: rgb(233, 158, 158);
}

.device-box-uvc .data-table th {
	text-align: start;
	background-color: #a6c4eb;
}

.device-box-uvc:hover {
	background-color: rgb(235, 242, 255);
}

.device-box-inactive {
    background-color: #e4e4e4;
    color: #646464;
	opacity: 0.7;
	
}

.device-box-inactive:hover {
    background-color: #fff;
}

.device-box-data {
    padding: 5px;
}

.device-box-data h2 {
    margin: 5px;
    border-bottom: 1px solid #b8b8b8;
}

.device-box-data h3 {
    margin-left: 10px;
    font-size: 1em;
    font-style: italic;
}

.device-box .device-box-title {
    font-size: 1.5em;
    background-color: #110f2e;
    padding: 5px;
    color: #fff;
	border-radius: 6px 6px  0px 0px;
}

.device-box .deviceHeader {
    border-radius: 0;
    border: none;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
}

.device-box .device-name-input-cont {
	background-color: #505050;
	text-align: center;
	padding: 5px;
}

.device-box .device-name-input {
	font-size: 1.5em;
	font-weight: bold;
	border-radius: 0px;
	border: none;
	text-align: center;
	background-color: inherit;
	color: #fff;
	width: 100%;
	
}

.device-box .device-name-input:focus {
	outline: none;
}

.device-box .temperature {
    margin: auto;
}

.device-box .msgcount,.device-box .temperature {
    font-size: 3em;
    background-color: inherit;
    text-align: center;
    color: #000;
}

.device-box .device-data-field {
	margin: auto;
	text-align: center;
}

.device-box .device-data-label {
	margin: 1px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
}

#user-info {
	background-color: inherit;
}

#user-info:hover {
	animation-duration: 300ms;
	background-color: #ffffff38;
	cursor: pointer;
}

.settings-table td {
	padding: 10px;
}

.settings-table input {
	font-size: 1.1em;
	padding: 10px;
}

input[type=submit] {
	cursor: pointer;
}

.login-container {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.login-container input {
	display: block;
	align-self: center;
}

.login-container h1 {
	font-size: 3em;
	text-align: center;
}


.mapview {
	box-sizing: border-box;
	width: 100%;
	height: 100vh;
}

.leaflet-container {
	height: 100%;
}

.clickable:hover {
	cursor: pointer;
}

.map-sidebar {
	position: absolute;
	right: 0px;
	top: 0px;
	height: 100%;
	box-sizing: border-box;
	z-index: 500;
	background-color: rgba(255, 255, 255, 0.7);
	/* box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.05); */
	padding: 20px;
	padding-top: 8vh;
	min-width: 10%;
}

.map-sidebar-device {
	padding: 5px;
	background-color: white; 
	margin: 3px; 
	border-radius: 3px;
	cursor: grab;
	/* box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.1); */
}

.map-legend {
	position: absolute; 
	/* left: 170px;  */
	bottom: 0; 
	background-color: rgba(255, 255, 255, 0.5);
	padding: 5px; 
	z-index: 9999;
}

.dyn-box-view {
	width: 100%;
}

.dyn-box-view.settings {
	display: flex;
	gap: 20px;
	width: 100%;
	padding: 20px;
	box-sizing: border-box;
}

.dyn-box-view .dyn-box-view-col {
	display: flex;
	flex-direction: column;
	gap: 20px;
	flex: 1;
}



.dyn-box {
	background-color: #fff;
    margin: 10px;
    padding: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
	flex: 1;
	border-radius: 6px;
}


.dyn-box.settings {
	/* flex: 1; */
	display: flex;
	gap: 20px;
	flex-direction: column;
	background-color: white;
	/* box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1); */
	padding: 15px;
	height: fit-content;
	width: 100%;
	box-sizing: border-box;
	margin: 0;
	border-radius: 6px;
}

.dyn-box .title {
font-size: 24px;
font-weight: 700;
}

.report-dyn-box {
	flex: 1;
	background-color: white;
	margin: 10px;
	padding: 10px;
	height: fit-content;
	border-radius: 6px;
}

.dyn-box table {
	width: 100%;
}

.dyn-box th {
	text-align: left;
}

.device-box-select-row {
	background-color: inherit;
}

.device-box-select-row:hover {
	background-color: #EEE;
}

.select-box {
	cursor: pointer;
	opacity: initial;
}

.select-box:hover {
	opacity: 0.8;
}

.chart-crosshair {
	background-color: rgba(50, 50, 50, 0.8);
	color: white;
	padding: 10px;
	border-radius: 5px;
}

.system-tree-field {
	margin: 1px;
	padding: 1px;
	display: block;
}

.channel-setups input[type=number], .channel-setups select {
	width: 100%;
	box-sizing: border-box;
	margin-left: 5px;
}

/*
	Error styles
*/
.error {
	background-color: rgb(230, 145, 135);
	color: black;
}

/*
	Warning styles
*/
.warning {
	background-color: rgb(255, 244, 147);
	color: black;
}

/*
	No error
*/
.no-error {
	background-color: rgb(158, 224, 152);
	color: black;
}

/*
	Default styles
*/
.default {
	background-color: rgb(255, 255, 255);
	color: black;
}

@keyframes inherit-grey {
	from {background-color: inherit;}
	to {background-color: grey;}
}




.profile-info {
	display: flex;
}

.logo .text {
	display: flex;
}

.logo {
	padding: 0px;
}

.reports {
	display: grid;
	grid-template-columns: repeat(3, 1fr); /* Three equal columns */
	gap: 10px;
  }


  .reports .item {
	background: #F8F8FB;

  }

  .reports .item:hover {
	background: #efeff3;

  }


  .select {
	width: 200px;

  }

  .Dropdown-root {
	font-weight: 500;
}
  
.Dropdown-control {
    border-radius: 6px !important;
}

  .Dropdown-menu {
    border-radius: 6px !important;
	margin-top: 5px !important;
}


  .profile-button {
	background-color: #4c45cc31;
	border-radius: 6px;
  }

  .profile-button:hover {
	background-color: #4c45cc52;
  }

  .Dropdown-arrow {
	top: 16px !important;
  }
  
  .device-report {
	flex-direction: row;
  }



@media only screen and (max-width: 768px) { 

	.reports {
		grid-template-columns: 1fr; /* Single column */
	  }
	  

	.dyn-box-view.settings {
		grid-template-columns: 1fr;
	  }

	  .report-detail {
		flex-direction: row;
	  }
	

}


/*
	MOBILE
*/
@media only screen and (max-width: 1024px) { 

	.sidebar-button .icon-label {
		display: none;
	}

	.profile-info {
		display: none;
	}	
	
	.logo .text {
		display: none;
	}

	.logo {
		padding: 0px;
	}


	.side-bar {
		width: 50px;
	}

	.side-bar {
		padding: 5px;
	}

	.content-wrapper {
		padding-left: 0px;
	}

	.top-bar .title-main {
		font-size: 1.5em;
	}

	.top-bar .title-sub {
		font-size: 0.9em;
	}

	.top-bar .org-title {
		display: none;
	}

	.map-legend {
		left: 48px; 

	}

	.device-box td, th {
		font-size: 0.8em;
	}

	.dyn-box-view.settings {
		flex-direction: column;
		}
	
		.device-report {
			flex-direction: column;
		  }	
		  
		  .report-detail {
			flex-direction: column;
		  }

		  
}

